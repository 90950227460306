globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"c12e34a8812f54cc7485e00450558682cdbfa174"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

Sentry.init({
  environment: publicRuntimeConfig.stage,
  dsn: publicRuntimeConfig.sentryDsnFe,
  tracesSampleRate: 1.0,
});
